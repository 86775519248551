import React, {
  Component,
  ReactNode
} from 'react';
import {
  injectIntl,
  IntlShape
} from 'react-intl';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  ELSTokenHelper,
  ELSURLHelper
} from '@els/els-ui-common-react';
import { Dropdown } from '@els/els-react--dropdown';
import {
  ELSFillTheBlank,
  ELSMultipleAnswer,
  ELSSingleBestAnswer
} from '@els/els-component-assessment-react';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { locationActions } from '../../redux/location/location.actions';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import StudentStudyHeaderNavComponent from '../../components/student-study-header-nav/StudentStudyHeaderNav.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  fetchEaqAssessment,
  fetchEaqAssignment,
  fetchStudentPerformanceDetails
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import * as constants from '../eaq-student-study/eaq-student-study.constants';
import {
  AssessmentDto,
  AssessmentPerformanceDetailsDto,
  AssessmentTypeDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  ELSGaugeChart,
  ELSTab,
  ELSTabGroup
} from '../../components/els.components';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import sassConstants from '../../assets/_constants.scss';
import { fetchAssessmentByAssignmentIdAndUserId } from '../../apis/eols-assessment-service/eols-assessment-service.utilities';
import {
  getAssessmentScore,
  getFormattedAssessmentGoal,
  getFormattedAssessmentType,
  getFormattedAssignmentGradeType,
  renderFormattedDate
} from '../../utilities/eaq-self-study.utilities';
import { DropDownOption } from '../../models/ui.model';
import { OrderQuestionDisplay } from '../../components/order-question-display/OrderQuestionDisplay.component';
import { QuestionType } from '../../constants/assessment.constants';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { addSearchParams } from '../../utilities/app.utilities';
import { RoutePath } from '../../components/app/app.constants';
import {
  createSupportTicketDto,
  HelpLink
} from '../../utilities/help.utilities';
import { RightNowApplication } from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.dtos';
import { ELSTokenUser } from '../../models/els.dtos';
import { Messages } from '../../translations/message.models';
import { postGetSupportTicketUrl } from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.utilities';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  assessments: studySelectors.getStudentStudyAssessments(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  course: studySelectors.getCourse(state),
  evolveUser: studySelectors.getEvolveUser(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type EaqStudentPerformanceDetailsProps = PropsFromRedux & RouteComponentProps & { intl: IntlShape };

interface EaqStudentPerformanceDetailsState {
  assessmentType: AssessmentTypeDto;
  assessmentGoal: string;
  assessmentResults: AssessmentPerformanceDetailsDto | null;
  studentName: string;
  activeTabIndex: number;
  activeDropdownOption: ReactNode;
  dropdownOptions: DropDownOption[];
  assignmentGradeType: string;
  assessmentStartDate: string;
  assessmentCompleteDate: string;
  assignmentDueDate: string;
}

export class EaqStudentPerformanceDetails extends Component<EaqStudentPerformanceDetailsProps, EaqStudentPerformanceDetailsState> {
  constructor(props: EaqStudentPerformanceDetailsProps) {
    super(props);
    this.state = {
      assessmentType: null,
      assessmentGoal: '',
      assessmentResults: null,
      studentName: '',
      activeTabIndex: 0,
      dropdownOptions: [
        {
          id: 'all',
          label: 'all'
        },
        {
          id: 'incorrect',
          label: 'incorrect'
        },
        {
          id: 'correct',
          label: 'correct'
        }
      ],
      activeDropdownOption: { id: 'all', label: 'all' },
      assignmentGradeType: '',
      assessmentStartDate: '',
      assessmentCompleteDate: '',
      assignmentDueDate: '',
    };
  }

  componentDidMount() {
    const eolsUser = ELSTokenHelper.getUser();

    this.handleLaunch();

    this.setState({
      studentName: `${eolsUser.firstName} ${eolsUser.lastName}`,
    });
  }

  handleTabChange = (index: number) => {
    this.setState({
      activeTabIndex: index
    });
  };

  handleReturnNavigation = () => {
    const { redirect } = this.props;

    return redirect(addSearchParams(RoutePath.EAQ_STUDENT_STUDY, null));
  };

  handleLaunch = async (): Promise<void> => {
    const { appLinkData, messages } = this.props;
    let assignment: AssignmentDto;
    let assessment: AssessmentDto;
    let assessmentId: number;
    const isInstructorLedAssignment = appLinkData && appLinkData.outPostBody && Object.keys(appLinkData.outPostBody)
      .includes('assignmentId');

    if (isInstructorLedAssignment) {
      const eolsUserId = ELSTokenHelper.getUserId();
      assignment = await fetchEaqAssignment(appLinkData.outPostBody.assignmentId);
      assessment = await fetchAssessmentByAssignmentIdAndUserId(eolsUserId, assignment.id);
      assessmentId = assessment.id;
    } else if (!isInstructorLedAssignment) {
      assessmentId = Number(ELSURLHelper.getParameterByName('assessmentId', location.search));
      assessment = await fetchEaqAssessment(assessmentId);
    }

    const assessmentResults = await fetchStudentPerformanceDetails(assessmentId);

    return this.setState({
      assessmentResults,
      assessmentType: assessment.type,
      assessmentGoal: getFormattedAssessmentGoal(assessment.type, assessment.assessmentGoals[0].goal, messages),
      assessmentStartDate: assessment.createdAt,
      assessmentCompleteDate: assessmentResults.completedDateTime,
      assignmentDueDate: assessmentResults.dueDateTime,
      assignmentGradeType: assignment ? assignment.assignmentGradeType : null
    });
  };

  renderTotalQuestionsText = (assessmentResults: AssessmentPerformanceDetailsDto): JSX.Element => {
    const { intl, messages } = this.props;
    const totalQuestions: number = assessmentResults ? assessmentResults.questions.length : 0;
    const correctTotal: number = assessmentResults ? assessmentResults.totalCorrect : 0;
    const incorrectTotal: number = assessmentResults ? totalQuestions - correctTotal : 0;

    const formattedMessage = intl.formatMessage(
      {
        id: LANGUAGE_KEY.BASED_ON_TOTAL_QUESTIONS,
        defaultMessage: messages.BASED_ON_TOTAL_QUESTIONS
      },
      {
        totalQuestions: <b>{totalQuestions}</b>
      }
    );

    return (
      <div>
        <p>
          {formattedMessage} ({incorrectTotal} {messages.INCORRECT.toLowerCase()}, {correctTotal} {messages.CORRECT.toLowerCase()})
        </p>
      </div>
    );
  };

  handleReportContentErrorClick = () => {
    const { course, evolveUser } = this.props;
    const user: ELSTokenUser = ELSTokenHelper.getUser();

    const supportTicket = createSupportTicketDto(
      user,
      course,
      user.role,
      evolveUser,
      RightNowApplication.EAQ,
      HelpLink.RIGHT_NOW_REPORT_A_CONTENT_ERROR,
      null
    );

    postGetSupportTicketUrl(supportTicket).then((url) => {
      return window.open(url, '_blank');
    });
  };

  formatTextReference = (textReference: JSX.Element, messages: Messages): JSX.Element => {
    return (
      <div className="u-els-margin-top">
        <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.WRAP_AT_MOBILE]}>
          <FlexItem modifiers={[FlexLayoutModifier.GROW]} classes={['u-els-padding-top@mobile']}>
            {textReference ? (
              <button
                type="button"
                className="u-els-anchorize c-els-link"
                disabled
              >
                <IconWithText
                  iconPrefix="gizmo"
                  iconName="open-book"
                >
                  <p className="c-els-link__text u-els-font-size-base">{textReference}</p>
                </IconWithText>
              </button>
            ) : null}
          </FlexItem>
          <FlexItem
            modifiers={[FlexLayoutModifier.RIGHT]}
            classes={['u-els-width-1o1@mobile', 'u-els-padding-top-2x@mobile']}
          >
            <button
              type="button"
              className="u-els-anchorize c-els-link"
              onClick={() => this.handleReportContentErrorClick()}
            >
              <IconWithText
                iconPrefix="gizmo"
                iconName="writing"
              >
                <p className="c-els-link__text u-els-font-size-base u-els-font-family-base">{messages.REPORT_CONTENT_ERROR}</p>
              </IconWithText>
            </button>
          </FlexItem>
        </FlexLayout>
      </div>
    );
  };

  displayQuestionType = (question) => {
    const { qtiData } = question;
    const { messages } = this.props;
    const rationaleTypeBlock = 'block';
    const rationaleBlockTitle = <b>{messages.RATIONALE}</b>;
    const questionPrompt = qtiData.prompt;
    const textReference = qtiData.textReference
      ? this.formatTextReference(qtiData.textReference[0], messages) : [];

    switch (question.qtiData.questionType) {
      case QuestionType.MCSA:
        return (
          <ELSSingleBestAnswer
            questionPrompt={questionPrompt}
            questionChoices={qtiData.responseChoices}
            questionFeedback={qtiData.feedback}
            correctAnswers={qtiData.correctResponse}
            textReference={textReference}
            selectedAnswers={question.selectedAnswers}
            rationaleType={rationaleTypeBlock}
            rationaleBlockTitle={rationaleBlockTitle}
          />
        );
      case QuestionType.MCMA:
        return (
          <ELSMultipleAnswer
            questionPrompt={questionPrompt}
            questionChoices={qtiData.responseChoices}
            questionFeedback={qtiData.feedback}
            correctAnswers={qtiData.correctResponse}
            selectedAnswers={question.selectedAnswers}
            isShowSuccessFlagAllCorrectAnswer
            rationaleType={rationaleTypeBlock}
            textReference={textReference}
            rationaleBlockTitle={rationaleBlockTitle}
          />
        );
      case QuestionType.ORDER:
        return (
          <OrderQuestionDisplay
            question={{
              prompt: questionPrompt,
              responseChoices: qtiData.responseChoices,
              feedback: {
                feedback: qtiData.feedback[Object.keys(qtiData.feedback)[0]],
              },
              correctResponse: qtiData.correctResponse,
              textReference,
              questionType: qtiData.questionType
            }}
            selectedAnswers={question.selectedAnswers}
            rationaleBlockTitle={rationaleBlockTitle}
          />
        );
      case QuestionType.FILL_IN_BLANK:
        return (
          <ELSFillTheBlank
            questionPrompt={questionPrompt}
            questionChoices={qtiData.responseChoices}
            questionFeedback={qtiData.feedback}
            rationaleType={rationaleTypeBlock}
            rationaleBlockTitle={rationaleBlockTitle}
            textReference={textReference}
            correctAnswers={qtiData.correctResponse}
            selectedAnswers={question.selectedAnswers}
            isCompleted
          />
        );
      default:
        return null;
    }
  };

  generateAssessmentResultsMap = (assessmentResults: AssessmentPerformanceDetailsDto): JSX.Element => {
    const { messages } = this.props;

    if (!assessmentResults || !assessmentResults.questions) {
      return <p>No question data</p>;
    }

    return (
      <FlexLayout>
        <FlexItem>
          <div>
            {assessmentResults.questions.map((question, index) => (
              <div className="c-ssa-eaq-student-performance-details__question-item u-els-padding-2x u-els-margin-bottom-2x" key={question.qtiData.vtwId}>
                {/* correctness pill */}
                <span
                  className={`u-els-display-inline-block c-els-pill ${
                    question.correct
                      ? 'u-els-color-confirm u-els-background-color-confirm-background'
                      : 'u-els-color-warn u-els-background-color-warn-background'
                  }`}
                >
                  <IconWithText
                    iconName={question.correct ? 'checkmark' : 'close'}
                    iconPrefix="hmds"
                  >
                    {question.correct ? messages.CORRECT : messages.INCORRECT}
                  </IconWithText>
                </span>

                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                  {/* question position */}
                  <FlexItem>
                    <h4 className="u-els-margin-top u-els-margin-top-1x1o2@mobile">{index + 1}. </h4>
                  </FlexItem>
                  {/* question content */}
                  <FlexItem modifiers={[FlexLayoutModifier.GROW]} classes={['u-els-padding-left-1o2']}>
                    {this.displayQuestionType(question)}
                  </FlexItem>
                </FlexLayout>

              </div>
            ))}
          </div>
        </FlexItem>
      </FlexLayout>
    );
  };

  render() {
    const { messages } = this.props;
    const {
      assessmentResults,
      studentName,
      activeTabIndex,
      assessmentType,
      assessmentGoal,
      assessmentStartDate,
      assessmentCompleteDate,
      assignmentDueDate,
      assignmentGradeType,
      activeDropdownOption,
      dropdownOptions
    } = this.state;
    const isMasteryType = assessmentType === AssessmentTypeDto.MASTERY;

    return (
      <div className="c-ssa-eaq-student-performance-details">
        <StudentStudyHeaderNavComponent
          handleReturnNavigation={this.handleReturnNavigation}
          headerTitle={messages.ELSEVIER_ADAPTIVE_QUIZZING}
          isMenuItemsEnabled={false}
          headerTitleClassName="u-els-color-secondary"
          isSticky
        />

        {assessmentResults && assessmentResults.topics && (
          <div className="c-ssa-eaq-student-performance-details__description-block">

            <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.WRAP_AT_MOBILE]}>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
                  <FlexItem>
                    <strong><h2>{assessmentResults.quizName ? assessmentResults.quizName : assessmentResults.topics[0].text}</h2></strong>
                  </FlexItem>
                </FlexLayout>
                <FlexLayout modifiers={[
                  FlexLayoutModifier.LEFT,
                  FlexLayoutModifier.GUTTERS_2X,
                  FlexLayoutModifier.WRAP_AT_MOBILE,
                  FlexLayoutModifier.WRAP_AT_TABLET
                ]}>
                  <FlexItem
                    classes={isMasteryType
                      ? ['u-els-padding-left-3x@mobile u-els-padding-left-3x@tablet']
                      : ['u-els-padding-left-2x@mobile u-els-padding-left-2x@tablet']}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.STUDENT}:</h4>
                          </td>
                          <td>
                            <h4>{studentName}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.TYPE}:</h4>
                          </td>
                          <td>
                            <h4>{getFormattedAssessmentType(assessmentType, messages)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{isMasteryType ? messages.GOAL : messages.QUESTIONS}:</h4>
                          </td>
                          <td>
                            <h4>{assessmentGoal}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FlexItem>
                  <FlexItem>
                    <table>
                      <tbody>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.DUE}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentResults.dueDateTime)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.START}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentStartDate)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.COMPLETE}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentCompleteDate)}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FlexItem>
                </FlexLayout>
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.LEFT]} classes={['u-els-padding-top@mobile']}>
                <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE]}>
                  <FlexItem>
                    <div className="c-ssa-eaq-student-performance-details__gauge-chart gauge-chart__info">
                      <ELSGaugeChart
                        percent={getAssessmentScore(assignmentGradeType, assessmentResults)}
                        radius={50}
                        percentColor={sassConstants.elsColorExtendedBlue7}
                        offsetColor={sassConstants.elsColorN1}
                        label={(
                          <>
                            <b>{messages.FINAL_SCORE}</b><br />
                            {getFormattedAssignmentGradeType(assignmentGradeType, assessmentCompleteDate, assignmentDueDate, messages)}
                          </>
                        )}
                      />
                    </div>
                  </FlexItem>
                </FlexLayout>
              </FlexItem>
            </FlexLayout>
          </div>
        )}
        <div className="c-ssa-eaq-student-performance-details__page-content">
          <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE]}>
            <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
              <div className="c-ssa-eaq-student-performance-details__tab-group">
                <ELSTabGroup
                  onChangeActiveIndex={this.handleTabChange}
                  activeIndex={activeTabIndex}
                >
                  <ELSTab title={messages.QUESTIONS}>
                    <FlexLayout
                      modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.WRAP_AT_MOBILE]}
                      classes={['u-els-margin-bottom-2x']}
                    >
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        <h3>{messages.HOW_DID_I_PERFORM_ON_THIS_QUIZ}</h3>
                        {this.renderTotalQuestionsText(assessmentResults)}
                      </FlexItem>
                      <FlexItem
                        modifiers={[FlexLayoutModifier.RIGHT]}
                        classes={['u-els-width-1o1@mobile u-els-padding-top@mobile']}
                      >
                        <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
                          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2]}>
                            <FlexItem modifiers={[FlexLayoutModifier.RIGHT]}>
                              <p>{messages.VIEWING}</p>
                            </FlexItem>
                            <FlexItem>
                              <Dropdown
                                id="c-ssa-filter-dropdown"
                                options={dropdownOptions}
                                selected={activeDropdownOption}
                                showSelectedAsLabel
                                onChange={(newOptionSelected: DropDownOption) => {
                                  this.setState({ activeDropdownOption: newOptionSelected });
                                }}
                                flyoutProps={{
                                  pointer: false,
                                  pointerType: 'primary',
                                  placement: 'bottom-end'
                                }}
                              />
                            </FlexItem>
                            <FlexItem modifiers={[FlexLayoutModifier.LEFT]}>
                              <p>{messages.QUESTIONS.toLowerCase()}</p>
                            </FlexItem>
                          </FlexLayout>
                        </FlexLayout>
                      </FlexItem>
                    </FlexLayout>
                    <FlexLayout>
                      <FlexItem modifiers={[FlexLayoutModifier.CENTER]}>
                        {this.generateAssessmentResultsMap(assessmentResults)}
                      </FlexItem>
                    </FlexLayout>
                  </ELSTab>

                  <ELSTab title={messages.TOPIC}>
                    <p>in progress</p>
                  </ELSTab>
                </ELSTabGroup>
              </div>
            </FlexItem>
          </FlexLayout>
        </div>
      </div>
    );
  }
}

const enhancers = [
  withHTMLHeadSEO({ title: constants.eaqStudentStudyPerformanceDetailsPageTitle }),
  injectIntl,
  connector,
  withPageLoader
];

export default compose(...enhancers)(EaqStudentPerformanceDetails);
