import moment from 'moment';
import { EaqStudentPerformanceDto, EaqStudentTopicPerformanceDto } from '../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { TopicPerformanceTableRow, TopicWithLineage } from '../pages/eaq-student-topic-report/eaq-student-topic-report.models';
import {
  getPercentageAnsweredCorrectly,
  getStudentPerformance,
  getTimeSpentFormat
} from '../pages/eaq-student-topic-report/eaq-student-topic-report.utilities';
import {
  AssessmentTypeDto,
  AssignmentGradeType,
  AssessmentPerformanceDetailsDto
} from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { LANGUAGE_KEY } from '../translations/message.constants';
import { AppAction, Application } from '../apis/eols-app-link/eols-app-link.constants';
import { getEaqNextQuestionApi, submitEaqQuestionApi } from '../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import { NavigateToApp } from '../redux/student-study/studentStudy.models';
import { Emdash } from '../constants/studentStudy.constants';

export const getTopicTableRows = (flattenedTopics: TopicWithLineage[]): TopicPerformanceTableRow[] => {
  if (!flattenedTopics || !flattenedTopics.length) {
    return null;
  }

  return flattenedTopics.map((topic: TopicWithLineage): TopicPerformanceTableRow => {
    const studentPerformance: EaqStudentPerformanceDto = getStudentPerformance(topic.item);
    return {
      ...studentPerformance,
      topic,
      topicName: topic.item.displayName || topic.item.text,
      lineageDisplay: topic.lineage
        .map((parent: EaqStudentTopicPerformanceDto) => {
          return parent.displayName || parent.text;
        }).join(' / '),
      lineageDisplayOrder: topic.lineage.map((item: EaqStudentTopicPerformanceDto) => {
        return item.displayOrder + (item.displayName || item.text);
      }).join('.'),
      timeSpentDisplay: getTimeSpentFormat(studentPerformance.timeSpent),
      percentCorrect: getPercentageAnsweredCorrectly(studentPerformance)
    };
  });
};

export const getFormattedAssessmentGoal = (
  assessmentType: AssessmentTypeDto,
  assessmentGoal: number,
  messages: Partial<Record<LANGUAGE_KEY, string>>
) => {
  if (assessmentType === AssessmentTypeDto.MASTERY) {
    switch (Math.floor(assessmentGoal)) {
      case 1:
        return messages.NOVICE;
      case 2:
        return messages.INTERMEDIATE;
      case 3:
        return messages.PROFICIENT;
      default:
        return Emdash;
    }
  }
  return assessmentGoal.toString();
};

export const getAssessmentScore = (assignmentGradeType: string, assessmentResults: AssessmentPerformanceDetailsDto): number => {

  const score: number = assignmentGradeType === AssignmentGradeType.PASS_FAIL
    ? assessmentResults.totalCorrect / assessmentResults.totalAnswered
    : assessmentResults.percentage;

  return Math.floor(score * 100);
};

export const getFormattedAssignmentGradeType = (
  assessmentGradeType: string,
  assessmentCompleteDate: string,
  assignmentDueDate: string,
  messages: Partial<Record<LANGUAGE_KEY, string>>
) => {
  if (assessmentGradeType === AssignmentGradeType.PASS_FAIL) {
    return moment(moment.utc(assessmentCompleteDate).local())
      .isSameOrBefore(moment.utc(assignmentDueDate).local()) ? messages.PASS : messages.FAIL;
  }
  if (assessmentGradeType === AssignmentGradeType.NOT_GRADED) {
    return messages.NOT_GRADED;
  }
  return '';
};

export const getFormattedAssessmentType = (
  assessmentType: string,
  messages: Partial<Record<LANGUAGE_KEY, string>>
) => {
  switch (assessmentType) {
    case AssessmentTypeDto.MASTERY:
      return messages.MASTERY;
    case AssessmentTypeDto.STANDARD:
      return messages.CUSTOM;
    case AssessmentTypeDto.QUIZ_BY_QUESTION:
      return messages.CUSTOM_QUESTIONS;
    default:
      return Emdash;
  }
};

export const renderFormattedDate = (date: string) => {
  if (!date) {
    return Emdash;
  }

  return `${moment(moment.utc(date).local()).format('D MMM YYYY')} at ${moment(moment.utc(date).local()).format('h:mm a')}`;
};

export const navigateToAssessmentPlayer = (navigateToApp: NavigateToApp, parentLinkId: string, assessmentId: number, returnPage?: string): void => {
  return navigateToApp({
    action: AppAction.ASSESSMENT_START,
    app: Application.NHE_ASSESSMENT_PLAYER,
    body: {
      assessmentId,
      getNextQuestionAPI: getEaqNextQuestionApi(assessmentId),
      submitQuestionAPI: submitEaqQuestionApi(assessmentId),
      assessmentPlayerConfig: {
        exitToSourceButton: true, // * - this is true by default but setting here in case that changes
        navigationType:
          'Submit-Continue',
        strictStudyMode: false
      },
      ref: returnPage
    },
    altSrcApp: Application.STUDENT_STUDY,
    parentLinkId,
    includeLinkHash: true
  });
};
