import {
  AssessmentTypeDto,
  QtiDataDto
} from '../eols-assessment-service/eols-assessment-service.dtos';

// * - assessmentPerformanceResponse will always return null after AXE-2519, we will use assessmentSubmission instead
export interface StudentStudyDto {
  assessment: {
    id: number;
    assessmentGoals: AssessmentGoalsDto[];
    assessmentTopics: AssessmentTopicsDto[];
    type: AssessmentTypeDto;
    createdAt: string;
  };

  assessmentPerformanceResponse?: {
    totalAnswered: number;
    completedDateTime: string;
    percentage: number;
    questions: StudentPerformanceQuestionDto[];
  };

  assessmentQuestionList: AssessmentQuestionListDto[];

  topicPerformanceResponseList: TopicPerformanceResponseListDto[];

  assessmentSubmission?: {
    id: number;
    assessmentId: number;
    userId: number;
    score: number;
    totalCorrect: number;
    totalAnswered: number;
    responseTime: string;
    offsetSeconds: number;
    timeSpent: number;
    createdAt: string;
    updatedAt: string;
  };
}

export type AssessmentQuestionListDto = {
  assessmentId: number;
  confident: boolean;
  correct: boolean;
  courseSectionId: number;
  id: number;
  questionPosition: number;
  questionVtwId: string;
  requestTime: string;
  responseTime: string;
  selectedAnswers: string;
  timeSpent: number;
  userId: number;
}

export type TopicPerformanceResponseListDto = {
  displayName: string;
  displayOrder: number;
  id: string;
  leaf: boolean;
  level: number;
  root: boolean;
  studentPerformances: StudentPerformanceDto[];
  text: string;
  vtwId: string;
}

export type StudentPerformanceDto = {
  masteryLevel: number;
  timeSpent: number;
  questionsAnswered: number;
  questionsConfident: number;
  questionsCorrect: number;
  topicVtwId: string;
}

export type StudentPerformanceQuestionDto = {
  timeSpent: number;
}

export type AssessmentGoalsDto = {
  goal: number;
}

export type AssessmentTopicsDto = {
  text: string;
  vtwId: string;
}

export type EaqQuestionSetDto = {
  isbn: string;
  title: string;
}

export type TaxonomyRootPathDto = {
  id: string;
  text: string;
  level: number;
  eaqISBN: string;
  thresholdScore: string;
  totalScore: string;
  totalQuestions: string;
  averageNormalizedDifficulty: number;
}

export type TaxonomyPathDto = {
  taxonomyNodeId: string;
  orderedTaxonomyRootPath: TaxonomyRootPathDto[];
}

export type EaqQuestionDetailDto = {
  vtwId: string;
  qtiData: QtiDataDto;
  taxonomyPaths: TaxonomyPathDto[];
  difficulty: number;
  normalizedDifficulty: number;
  quiztopicIds: string[];
}

export interface CourseISBNDto {
  title: string;
  isbn: string;
  type: string;
}

export enum BookTaxonomyKindDto {
  ALL = 'ALL',
  PRIMARY = 'PRIMARY'
}

export type EaqTaxonomyDto = {
  id: string;
  vtwId: string;
  text: string;
  root: boolean;
  leaf: boolean;
  displayOrder: number;
  level: number;
  displayName?: string;
  children?: EaqTaxonomyDto[];
  description?: string;
}

export type EaqStudentPerformanceDto = {
  eolsUser: {
    id: number;
    lmsExternalId: string;
    externalId: string;
    firstName: string;
    lastName: string;
    username: string;
    emailAddress: string;
    lastSeenDate: unknown;
    updatedAt: string;
    type: string;
    eolsUserRoles: unknown[];
  };
  masteryLevel: number;
  questionsAnswered: number;
  questionsConfident: number;
  questionsCorrect: number;
  timeSpent: number;
}

export type EaqStudentTopicPerformanceDto = {
  children?: EaqStudentTopicPerformanceDto[];
  displayName: string;
  displayOrder: number;
  id: string;
  leaf: boolean;
  level: number;
  root: boolean;
  studentPerformances: EaqStudentPerformanceDto[];
  text: string;
  vtwId: string;
}

export type EaqTopicDto = {
  displayName: string;
  displayOrder: number;
  id: string;
  leaf: boolean;
  level: number;
  root: boolean;
  studentPerformances: EaqStudentPerformanceDto[];
  text: string;
  vtwId: string;
  children?: EaqTopicDto[];
}

export type AuthessAssignmentDto = {
  id: number;
  key: string;
  title: string;
  isbn: string;
  numberOfQuestions: number;
}

export type AuthessAssignmentsMapDto = Record<string, boolean>

export type EaqRecommenderQuestionsDto = {
  keyword: string;
  page: number;
  size: number;
  questionVtwIds: string[];
  isbnTopicMap: Record<string, string[]>;
  questionCount: boolean;
  courseISBNS: string[];
  excludedQuestionIds: string[];
  questionTypes: string[];
}
