import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { CourseSectionDto } from '../apis/eols-course-crud/eols-course-crud.dtos';
import { CrosswalkUserDto } from '../apis/eols-user-management-service/eols-user-management-service.dtos';
import {
  RightNowApplication,
  SupportTicketPostBodyDto,
  SupportTicketTypeDto
} from '../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.dtos';
import { addSearchParams } from './app.utilities';
import { ELSTokenUser } from '../models/els.dtos';

export enum HelpLink {
  ACCESSIBILITY = 'ACCESSIBILITY',
  FEEDBACK = 'FEEDBACK',
  PARTICIPATE = 'PARTICIPATE',
  SUPPORT = 'SUPPORT',
  UPDATES_AND_TRAINING = 'UPDATES_AND_TRAINING',
  RIGHT_NOW_REPORT_A_BUG = 'RIGHT_NOW_REPORT_A_BUG',
  RIGHT_NOW_REPORT_A_CONTENT_ERROR = 'RIGHT_NOW_REPORT_A_CONTENT_ERROR',
  RIGHT_NOW_CONTACT_US = 'RIGHT_NOW_CONTACT_US',
}

export const getCourseInstitution = (course: CourseSectionDto): string => {
  if (!course || !course.institution) {
    return '';
  }
  return course.institution.name;
};

export const createSupportTicketDto = (
  user: ELSTokenUser,
  course: CourseSectionDto,
  roleId: string,
  evolveUser: CrosswalkUserDto,
  app: RightNowApplication,
  helpLink: HelpLink,
  urlParams: Record<string, string>
): SupportTicketPostBodyDto => {
  let ticketType: SupportTicketTypeDto = null;

  if (helpLink === HelpLink.RIGHT_NOW_CONTACT_US) {
    ticketType = SupportTicketTypeDto.CONTACT_US;
  }

  if (helpLink === HelpLink.RIGHT_NOW_REPORT_A_BUG) {
    ticketType = SupportTicketTypeDto.BUG_REPORT;
  }

  if (helpLink === HelpLink.SUPPORT) {
    ticketType = SupportTicketTypeDto.ASK_FOR_HELP;
  }

  if (helpLink === HelpLink.RIGHT_NOW_REPORT_A_CONTENT_ERROR) {
    ticketType = SupportTicketTypeDto.CONTENT_PROBLEM;
  }

  return {
    eolsCourseId: course.id,
    eolsUserId: user.userId,
    eolsUserName: user.username,
    ticketType,
    contextUrl: addSearchParams(window.location.href, urlParams),
    appIdType: app,
    evolveUserName: evolveUser.userName,
    evolveInstitution: getCourseInstitution(course),
    userRole: roleId === ELSCommonUIConstants.userRole.STUDENT ? ELSCommonUIConstants.Student : roleId,
  };
};
