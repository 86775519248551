export enum SupportTicketTypeDto {
  CONTACT_US = 'CONTACT_US',
  CONTENT_PROBLEM = 'CONTENT_PROBLEM',
  BUG_REPORT = 'BUG_REPORT',
  ASK_FOR_HELP = 'ASK_FOR_HELP',
  VIEW_HISTORY = 'VIEW_HISTORY',
}

export enum RightNowApplication {
  SHERPATH = 'SHERPATH',
  HESI = 'HESI',
  EAQ = 'EAQ',
  NCO = 'NCO',
  EVOLVE = 'EVOLVE',
  SHER_EVOL = 'SHER_EVOL',
  SIMCHART = 'SIMCHART',
  EAB = 'EAB',
  SHADOW_HEALTH = 'SHADOW_HEALTH'
}

export type SupportTicketPostBodyDto = {
  appIdType: RightNowApplication;
  ticketType: SupportTicketTypeDto;
  eolsUserId: number;
  eolsCourseId: number;
  eolsUserName: string;
  contextUrl: string;
  evolveInstitution: string;
  evolveUserName: string;
  browserVersion?: string;
  browserType?: string;
  operatingSystem?: string;
  operationSystemVersion?: string;
  userRole?: string;
}
