import React from 'react';
import cx from 'classnames';
import { ResourceQtiDataDto } from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { ELSIcon } from '../els.components';

export interface OrderQuestionsDisplayProps {
  question: ResourceQtiDataDto;
  selectedAnswers?: string[];
  rationaleBlockTitle?: JSX.Element;
}

const getCorrectOrder = (correctResponse: string[] | string[][], selectedAnswer: string) => {
  if (correctResponse && selectedAnswer) {
    const formattedCorrectResponse: string[] = Array.isArray(correctResponse[0]) ? correctResponse.flat() : correctResponse;
    return formattedCorrectResponse.indexOf(selectedAnswer) + 1;
  }
};

export const OrderQuestionDisplay = (props: OrderQuestionsDisplayProps) => {
  const {
    question,
    selectedAnswers,
    rationaleBlockTitle
  } = props;

  const { correctResponse } = question;
  const { responseChoices } = question;

  const displayOrderQuestions = selectedAnswers || correctResponse;
  return (
    <div>
      <h4
        role="presentation"
        className="o-els-container u-els-margin-bottom-2x question-prompt__content question-prompt__content--selection"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: question.prompt }}
      />
      <ul className="c-ssa-reorder">
        {
          (displayOrderQuestions as string[])
            .map((questionItem, index) => {
              const isCorrect = questionItem === correctResponse[index];
              return (
                <li className="c-ssa-reorder__row" key={JSON.stringify(questionItem)}>
                  <div className="o-els-flex-layout o-els-flex-layout--gutters">
                    <div className="o-els-flex-layout__item">
                      <div className="c-ssa-reorder__context">
                        <div className="o-els-flex-layout o-els-flex-layout--middle o-els-flex-layout--left o-els-flex-layout--gutters">
                          <div className="o-els-flex-layout__item">
                            <div className={cx('c-ssa-answer-icon c-ssa-answer-icon--visible', {
                              'c-ssa-answer-icon--correct': isCorrect,
                              'c-ssa-answer-icon--incorrect': !isCorrect,
                            })}>
                              <ELSIcon
                                name={isCorrect ? 'checkmark' : 'close'}
                                prefix="hmds"
                                size="1x"
                              />
                            </div>
                          </div>
                          <div className="o-els-flex-layout__item">
                            <div className="c-ssa-reorder__number">{index + 1}.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
                      <div className="c-ssa-reorder__item">
                        <div className="o-els-flex-layout o-els-flex-layout--middle o-els-flex-layout--gutters">
                          <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
                            <h4 className="c-ssa-reorder__text">{responseChoices[questionItem]}</h4>
                          </div>
                          <ELSIcon
                            name="drag-handle"
                            size="2x"
                            prefix="hmds"
                          />
                        </div>
                      </div>
                      {!isCorrect && (
                        <div className="c-ssa-reorder__feedback">
                          <div className="u-els-italic u-els-bold u-els-color-warn">
                            Correct order: {getCorrectOrder(correctResponse, questionItem)}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
        }
      </ul>

      <h4 className="u-els-margin-top-4x">
        <div className="u-els-font-family-bold u-els-margin-top">
          {rationaleBlockTitle}
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <p className="rationale__text" dangerouslySetInnerHTML={{ __html: question.feedback.feedback }} />
      </h4>
      <p className="u-els-margin-top u-els-font-family-italic">
        {question.textReference}
      </p>

    </div>
  );
};
